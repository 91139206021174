<template>
  <b-card>
    <b-tabs>
      <b-tab
        active
        title="Área"
      >
        <consolidado-agencia />
      </b-tab>
      <b-tab
        title="Sucursal"
      >
        <consolidado-sucursal />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import ConsolidadoAgencia from '@/views/consolidadoCorona/ConsolidadoArea.vue'
import ConsolidadoSucursal from '@/views/consolidadoCorona/ConsolidadoSucursal.vue'

export default {
  name: 'ConsolidadoGeneral',
  components: {
    'consolidado-agencia': ConsolidadoAgencia,
    'consolidado-sucursal': ConsolidadoSucursal,
  },
}
</script>

<style scoped>

</style>
