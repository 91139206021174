<template>
  <b-overlay :show="loading">
    <div>
      <b-card class="border mb-2">
        <b-form @submit.prevent>
          <app-collapse>
            <app-collapse-item title="Búsqueda">
              <b-row>
                <b-col md="6">
                  <b-form-group>
                    <h5 class="font-weight-bold">
                      Fecha Desde
                    </h5>
                    <div class="input-group">
                      <flat-pickr
                        v-model="fechaDesde"
                        class="form-control"
                        placeholder="Click para seleccionar..."
                        :config="config"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn-clear"
                          type="button"
                          title="Limpiar"
                          data-clear
                        >
                          <feather-icon
                            icon="XIcon"
                          />
                        </button>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <h5 class="font-weight-bold">
                      Fecha Hasta
                    </h5>
                    <div class="input-group">
                      <flat-pickr
                        v-model="fechaHasta"
                        class="form-control"
                        placeholder="Click para seleccionar..."
                        :config="config"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn-clear"
                          type="button"
                          title="Limpiar"
                          data-clear
                        >
                          <feather-icon
                            icon="XIcon"
                          />
                        </button>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col>
                  <div class="text-right">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      type="submit"
                      @click="buscar"
                    >

                      <feather-icon
                        icon="SearchIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Buscar</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </app-collapse-item>
          </app-collapse>
        </b-form>
      </b-card>

      <b-card class="mb-2">
        <h4 class="card-title">
          Consolidado de Área
        </h4>
        <b-row>
          <b-col
            md="6"
            sm="12"
            class="mb-2 mt-1"
          />
          <b-col
            sm="12"
            md="6"
            class="text-right mb-1"
          >
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="primary"
              type="submit"
              class="text-left"
              @click="exportar"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span class="align-middle">Exportar</span>

            </b-button>
          </b-col>
          <b-col
            sm="12"
            md="6"
            class="my-1 text-right"
          >
            <div class="d-flex align-items-center mb-1 mb-md-0">
              <label>Mostrar</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
                class="mx-50 col-md-2"
                @change="onChangePerPage"
              />
              <label>filas por página</label>
            </div>
          </b-col>
          <b-col
            md="6"
            sm="12"
            class="mb-2 mt-1"
          >
            <b-form-group
              label="Ordenar"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="sortBySelect"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  size="sm"
                  :options="sortOptions"
                  class="w-75"
                  @change="buscar"
                >
                  <template v-slot:first />
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                  class="w-25"
                  @change="buscar"
                >
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

          </b-col>

          <b-col
            cols="12"
          >
            <b-table
              id="my-table"
              ref="table"
              striped
              hover
              small
              :per-page="perPage"
              :items="consolidadoArea"
              :fields="fields"
              responsive
              class="mb-0"
              show-empty
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              :sort-direction="sortDirection"
              @sort-changed="sortChanged"
            >
              <template #cell(agencia)="data">
                {{ data.item.agencia ? data.item.agencia : '-' }}
              </template>
              <template #cell(porciento_aprobado)="data">
                <b-badge :variant="porciento_aprobados(data.item.porciento_aprobado)">
                  {{ data.item.porciento_aprobado }}
                </b-badge>
              </template>
              <template #cell(alumnos_matriculados)="data">
                <b-badge variant="light-primary">
                  {{ data.item.alumnos_matriculados }}
                </b-badge>
              </template>
              <template #cell(alumnos_aprobados)="data">
                <b-badge variant="light-success">
                  {{ data.item.alumnos_aprobados }}
                </b-badge>
              </template>
              <template #cell(alumnos_reprobados)="data">
                <b-badge variant="light-danger">
                  {{ data.item.alumnos_reprobados }}
                </b-badge>
              </template>
              <template #cell(alumnos_sin_evaluacion_final)="data">
                <b-badge variant="light-danger">
                  {{ data.item.alumnos_sin_evaluacion_final }}
                </b-badge>
              </template>
              <template #cell(alumnos_curso_no_iniciado)="data">
                <b-badge variant="light-danger">
                  {{ data.item.alumnos_curso_no_iniciado }}
                </b-badge>
              </template>
              <template #empty="scope">
                <div class="text-center">
                  No existen resultados
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="pl-1">
          <b-col
            cols="8"
            class="mt-3"
          >
            <div v-if="totalRows > 0">
              <span class="text-muted">  Mostrando del {{ from }} al {{ to }} de {{ totalRows }} filas</span>
            </div>
          </b-col>

          <b-col cols="4">
            <b-pagination
              :total-rows="totalRows"
              :value="page"
              :per-page="perPage"
              align="right"
              class="mt-3 mr-1"
              aria-controls="my-table"
              @change="onPageChange"
            />
          </b-col>

        </b-row>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BBadge,
  BButton, BCard,
  BCol, BFormGroup, BFormSelect, BPagination,
  BRow,
  BTable, BInputGroup,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import consolidadoCorona from "@/store/modules/consolidadoCorona";

export default {
  name: 'ConsolidadoAgencia',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BCard,
    BTable,
    BBadge,
    AppCollapse,
    AppCollapseItem,
    BPagination,
    BFormSelect,
    BInputGroup,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 50,
      page: 1,
      pageOptions: [50, 100, 500, 1000],
      fields: [
        { key: 'area' },
        {
          key: 'porciento_aprobado', label: '% de aprobado', tdClass: 'text-center', thClass: 'text-center', sortable: true,
        },
        {
          key: 'alumnos_matriculados', label: 'Alumnos matriculados', tdClass: 'text-center', thClass: 'text-center', sortable: true,
        },
        {
          key: 'alumnos_aprobados', label: 'Alumnos aprobados', tdClass: 'text-center', thClass: 'text-center', sortable: true,
        },
        {
          key: 'alumnos_reprobados', label: 'Alumnos reprobados', tdClass: 'text-center', thClass: 'text-center', sortable: true,
        },
        {
          key: 'alumnos_sin_evaluacion_final', label: 'Sin finalizar', tdClass: 'text-center', thClass: 'text-center', sortable: true,
        },
        {
          key: 'alumnos_curso_no_iniciado', label: 'Sin iniciar', tdClass: 'text-center', thClass: 'text-center', sortable: true,
        },

      ],
      sortOptions: [
        { value: 'porciento_aprobado', text: 'Porciento aprobados' },
        { value: 'alumnos_matriculados', text: 'Alumnos matriculados' },
        { value: 'alumnos_aprobados', text: 'Alumnos aprobados' },
        { value: 'alumnos_reprobados', text: 'Alumnos reprobados' },
        { value: 'alumnos_sin_evaluacion_final', text: 'Sin finalizar' },
        { value: 'alumnos_curso_no_iniciado', text: 'Sin iniciar' },
      ],
      fechaDesde: '',
      fechaHasta: '',
      sortBy: 'porciento_aprobado',
      sortDesc: false,
      sortDirection: 'asc',
      filterOn: [],
    }
  },
  computed: {
    totalRows() {
      return this.$store.state.consolidadoCorona.rowsTotal
    },
    to() {
      return this.$store.state.consolidadoCorona.toSelect
    },
    from() {
      return this.$store.state.consolidadoCorona.from
    },
    lastPage() {
      return this.$store.state.consolidadoCorona.lastPage
    },
    listado() {
      return this.$store.state.consolidadoCorona.list
    },
    currentPage() {
      return this.$store.state.consolidadoCorona.currentPage
    },
    loading() {
      return this.$store.state.consolidadoCorona.loadingArea
    },
    consolidadoArea() {
      return this.$store.state.consolidadoCorona.consolidadoArea
    },
    config() {
      return {
        locale: require('flatpickr/dist/l10n/es.js').default.es,
        dateFormat: 'd-m-Y',
        wrap: true,
      }
    },
  },
  mounted() {
    this.getConsolidadoArea()
  },
  methods: {
    getConsolidadoArea() {
      const filterData = {
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        sortDirection: this.sortDesc === false ? 'asc' : 'desc',
        sortBy: this.sortBy,
        page: this.page,
        perPage: this.perPage,
      }
      this.$store.dispatch('consolidadoCorona/getConsolidadoArea', filterData)
    },
    onPageChange(page) {
      this.page = page
      this.getConsolidadoArea()
    },
    onChangePerPage() {
      this.page = 1
      this.getConsolidadoArea()
    },
    buscar() {
      this.getConsolidadoArea()
    },
    exportar() {
      if (this.totalRows == 0) {
        this.$bvToast.toast('No existen resultados para exportar', {
          title: 'ALERTA!!',
          variant: 'danger',
          solid: true,
        })
      } else {
        this.getExportData()
      }
    },
    getExportData() {
      const filterData = {
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        sortDirection: this.sortDesc === false ? 'asc' : 'desc',
        sortBy: this.sortBy,
        page: this.page,
        perPage: this.perPage,
      }
      this.$store.dispatch('consolidadoCorona/exportDataArea', filterData)
    },
    sortChanged(e) {
      this.sortBy = e.sortBy
      this.sortDesc = e.sortDesc
      this.getConsolidadoArea()
    },
    porciento_aprobados(porciento1) {
      const cadena = porciento1.slice(0, -2)
      const porciento = parseInt(cadena)
      if (porciento <= 50) {
        return 'light-danger'
      }
      if (porciento > 50 && porciento <= 70) {
        return 'light-warning'
      }
      if (porciento > 70) {
        return 'light-success'
      }
    },
  },
}
</script>
<style scoped lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .demo-inline-spacing.curso .custom-radio{
    margin-top: 0.5rem!important;

  }
  .btn-clear {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0px 3px 3px 0px!important;
    color: #6e6b7b;
  }
</style>
